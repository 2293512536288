import { useMutation, UseMutationResult } from 'react-query';

import {
  Login,
  GetSessions,
  ConnectRoom,
  ApproveLogoutOthers,
  Logout,
  GetSessionData,
  LoginSignUp,
  ForgotPasswordSendCode,
  ForgotPasswordChange,
  Watermark,
  WatermarkInfo,
  GetCurrentOptions,
  GetCurrentUser, RefreshToken, ChatConnection, ConnectStream, GetParticipants
} from '../queries/api';

export const useLogin = (): UseMutationResult<any> =>
  useMutation(async (payload: any) => {
    const data = await Login(payload);
    return data;
  });

export const useApproveLogoutOthers = () =>
  useMutation(async (payload: any) => {
    const data = (await ApproveLogoutOthers(payload));
    return data;
  });

export const useLoginSignUp = (): UseMutationResult<any> =>
  useMutation(async (payload: any) => {
    const data = (await LoginSignUp(payload));
    return data;
  });

export const useLogout = () =>
  useMutation(async (payload: any) => {
    (await Logout(payload));
  });

export const useGetSessions = (): any =>
  useMutation(async () => {
    const data = await GetSessions();
    return data;
  });

export const useGetSessionData = (id: string | string[] | undefined): any =>
  useMutation(async () => {
    const data = await GetSessionData(id);
    return data;
  });

export const useConnectRoom = (
  streamId: string | string[] | undefined,
): UseMutationResult<any> =>
  useMutation(async () => {
    const data = await ConnectRoom(streamId);
    return data;
  });
  export const useConnectStream = (
    streamId: string | string[] | undefined,
  ): UseMutationResult<any> =>
    useMutation(async () => {
      const data = await ConnectStream(streamId);
      return data;
    });

export const useForgotPasswordSendCode = (): UseMutationResult<any> =>
  useMutation(async (payload: any) => {
    const data = await ForgotPasswordSendCode(payload);
    return data;
  });

export const useForgotPasswordChange = (): UseMutationResult<any> =>
  useMutation(async (payload: any) => {
    const data = await ForgotPasswordChange(payload);
    return data;
  });

export const useWatermark = (sessionId: string | null): UseMutationResult<any> =>
  useMutation(async () => {
    const data = await Watermark(sessionId);
    return data
  })

export const useWatermarkInfo = (): UseMutationResult<any> =>
  useMutation(async (watermarkId: any) => {
    const data = await WatermarkInfo(watermarkId);
    return data;
  })

export const useGetCurrentUser = () =>
  useMutation(async () => {
    const data = await GetCurrentUser();
    return data;
  })

export const useRefreshToken = () =>
  useMutation(async (payload: { token: string, solution: string }) => {
    const data = await RefreshToken(payload);
    return data;
  })

export const useChatConnection = (sessionId: string | string[] | undefined): UseMutationResult<any> =>
  useMutation(async () => {
    const data = await ChatConnection(sessionId);
    return data;
  })
export const useGetParticipants = (roomId: string): UseMutationResult<any> =>
  useMutation(async () => {
   if (roomId) {
      const data = await GetParticipants(roomId);
      return data;
   }
    throw new Error('Invalid roomId');
  })

  export const useGetCurrentOptions = (sessionID: string | string[] | undefined): UseMutationResult<any> =>
  useMutation(async () => {
    const data = await GetCurrentOptions(sessionID);
    return data;
  })