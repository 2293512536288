import React from 'react';

interface ErrorMessageProps {
  text: string | string[];
}

export default function ErrorMessage({ text }: Readonly<ErrorMessageProps>) {
  return (
    <p className="warning-message">
      {text}
    </p>
  );
}

