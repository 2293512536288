import {joinPayload} from "../constants";
import {Ref} from "react";
import { selectMuteMicrophone } from "./zegoCloud";

export const websocketConnection = (authToken: string, roomData: any) => {


    let websocketUrlParams: string;
    if (authToken) {
        websocketUrlParams = `${roomData?.webSocketUrl}&Authorization=Bearer ${authToken}&SolutionContext=RP`;
    } else {
        const token = localStorage.getItem('token');
        websocketUrlParams = `${roomData?.webSocketUrl}&Authorization=Bearer ${token}&SolutionContext=RP`;
    }
    return new WebSocket(websocketUrlParams);
}

export const websocketOnOpen = (ws: WebSocket, setSocketData: Function, toggleJoinModal: Function) => {
   
    ws.onopen = function () {
        setSocketData(ws);

        ws.send(JSON.stringify(joinPayload));
        toggleJoinModal(true)
        startPing(ws);
    };
}

export const websocketOnMessageJoin = (ws: WebSocket, setAuthStep: Function, setStreamState: Function, setParticipants: Function, toggleJoinModal: Function, navigate: any, sessionIdFromParams: string | string[] | undefined) => {
    


    ws.onmessage = function (event: any) {
        const eventData = JSON.parse(event.data);
        if (eventData.errorMessage){setStreamState(eventData.errorMessage);}
        console.log('eventData', eventData)
        if (eventData.type === 'JOIN_REQUEST_ALLOWED') {
            setParticipants(eventData.joinedParticipants)
                toggleJoinModal(false);
                navigate(`/stream/${sessionIdFromParams}`);
            
        

            
          
        }

        if (eventData.type === 'JOIN_REQUEST_DENIED') {
            navigate('/logout')
            toggleJoinModal(false)
            setAuthStep('');
            setTimeout(() => {navigate('/auth')}, 5000)
        }
        
       
        (eventData.type === 'ERROR' && eventData.errorCode === 'room_user_joined_same_room') && navigate(`/stream/${sessionIdFromParams}`);
    };
}



export const websocketOnClose = (ws: WebSocket, authToken: string, roomData: any, setSocketData: Function) => {
    let wsNew: any
    ws.onclose = async function () {
        wsNew = await websocketConnection(authToken, roomData);

        if (wsNew) {
            wsNew.onopen = function () {
                setSocketData(ws);

                ws.send(JSON.stringify(joinPayload));
                startPing(ws);
            };
        }
    }; // disable onclose handler first
    ws.close();
    return wsNew;
}

export const startPing = (ws: any) => {
    console.log('starting pings')
    const pingInterval = setInterval(() => {
        console.log('Sending ping frame');
        ws.send(JSON.stringify({
            "type": "ping"
        }));
    }, 480000);

    ws.pingInterval = pingInterval;
};

export const stopPing = (ws: any) => {
    console.log('stopping pings')

    clearInterval(ws.pingInterval);
};
