import Participants from 'ui/components/Participants';

//keep 
export const zegoCloudConnection = async (data: any) => {
  if (data) {
    const zgEngine = await import('zego-express-engine-webrtc').then(
      ({ ZegoExpressEngine }) =>
        new ZegoExpressEngine(
          Number(data.zegoCloud?.appId),
          'wss://webliveroom1151032259-api.zegocloud.com/ws',
        ),
    );
    zgEngine.setLogConfig({logLevel: 'disable'})
    return { ...data.zegoCloud, zg: zgEngine, roomData: data };
  }
};

/*export const zegoCloudEvents = (zg: any, remoteUsersBlock: any, participants: any) => {
  zg.on('roomStateUpdate', (roomID: any,
    state: any,
    errorCode: any,
    extendedData: any) => {
    if (state == 'DISCONNECTED') {
      console.log('roomStateUpdate DISCONNECTED')
    }

    if (state == 'CONNECTING') {
      console.log('roomStateUpdate CONNECTING')
    }

    if (state == 'CONNECTED') {
      console.log('roomStateUpdate CONNECTED')
    }
  })

  zg.on(
    'roomStreamUpdate',
    async (
      roomID: any,
      updateType: any,
      streamList: any,
      extendedData: any
    ) => {

      console.log("$$999", participants)
      if (updateType == 'ADD') {
        await streamList.map((stream: any) => {
          zg.startPlayingStream(stream.streamID, {
            video: true,
            audio: true,
          }).then((remoteStream: any) => {

            const remoteUserVideo = document.createElement('video');

            function getParticipantName(participants: any, stream: any) {
              console.log("Participants", participants)
              const participant = participants.find((item: any) => stream.streamID.includes(item.userId));
              return participant ? `${(participant.firstName != '') ? participant.firstName : 'Guest'} ${participant.lastName}` : '';
            }

            // Create a text element
            const text = document.createElement('div');
            text.innerText = getParticipantName(participants, stream);
            text.style.position = 'relative';
            text.style.color = 'white';
            text.style.fontSize = '18px';

            // Append the text element to the video element


            // Set the styles and attributes for the video element

            remoteUserVideo.srcObject = remoteStream;
            remoteUserVideo.autoplay = true;
            remoteUserVideo.setAttribute('id', stream.streamID);

            remoteUserVideo.hidden = false;
            remoteStream.muted = false;

            const remoteUserVideoCard = document.createElement('div');
            remoteUserVideoCard.style.position = 'relative';
            remoteUserVideoCard.style.width = 'auto';
            remoteUserVideoCard.style.height = '200px';

            remoteUserVideoCard.appendChild(remoteUserVideo);
            remoteUserVideoCard.appendChild(text);
            // Append the text element to the video element

            remoteUserVideo.style.display = 'flex'
            remoteUserVideo.style.height = '150px'
            remoteUserVideo.style.objectFit = 'cover'
            remoteUsersBlock?.append(remoteUserVideoCard)
            remoteUserVideo.srcObject = remoteStream
            remoteUserVideo.autoplay = true
            remoteUserVideoCard.setAttribute('id', stream.streamID)
            remoteUserVideo.hidden = false
            remoteStream.muted = false
          }).catch((e: any) => { console.log('roomStreamUpdate error ', e) })
        })
      } else if (updateType == "DELETE" && zg && streamList[0].streamID) {
        streamList.map((stream: any) => {
          const currentVideo = document.getElementById(stream.streamID)
          currentVideo?.remove()
          zg.stopPlayingStream(stream.streamID)
        })
      }
    })

  zg.on(
    'roomUserUpdate',
    async (
      roomID: any,
      updateType: any,
      streamList: any,
      extendedData: any
    ) => {
      console.log('roomUserUpdate')
    }
  )

  zg.on('playerStateUpdate', async (result: any) => {
    console.log('playerStateUpdate ', result)
  })

  zg.on('remoteCameraStatusUpdate', async (result: any) => {
  })}*/

export const loginRoom = async (zg: any, roomId: any, token: any, userId: any) => {
  await zg.loginRoom(
    roomId,
    token,
    { userID: userId },
    { userUpdate: true },
  )
    .then(async (result: any) => {
      if (result === true) {
        console.log('zego cloud login success');
      }
    })
    .catch((error: any) => {
      console.log('error ', error)
    });
}


// export const zegoCloudLocStream = async (cameraStatus: any, zg: any, publishID: any, videoRef: any) => {
//   if (zg) {
//     const localVStream: any = await zg?.createZegoStream({
//       camera: {
//         audio: true,
//         video: true,
//         AEC: true,
//         ANS: true,
//         AGC: true,
//         channelCount: 2,
//       },
//     });
//     const localAStream: any = await zg?.createZegoStream({
//       camera: {
//         audio: true,
//         video: false,
//         AEC: true,
//         ANS: true,
//         AGC: true,
//         channelCount: 2,
//       },
//     });

//     //const localView = await zg?.createLocalStreamView(localStream);

//       if (!cameraStatus) {
//         videoRef.innerHTML = '';
//         if (!videoRef.hasChildNodes()){
//           localVStream.playCaptureVideo(videoRef);
//         }
//         zg.stopPublishingStream(publishID);
//         zg.startPublishingStream(publishID, localVStream);
//         return { localVStream };
//       } else {


//         videoRef.innerHTML = '';
//         localAStream.playVideo(videoRef);
//         zg.stopPublishingStream(publishID);
//         zg.startPublishingStream(publishID, localAStream);

//         return { localAStream };
//       }

//   }
// };

export const selectMicrophone = (deviceId: string, localStream: any, zg: any) => {
  if (deviceId) {
    zg.useAudioDevice(localStream, deviceId);
  }
};

export const selectMuteMicrophone = async (muteState: boolean, zg: any) => {
  await zg.muteMicrophone(muteState);
  // zg.mutePublishStreamAudio(zgLocStream, muteState);
};

export const selectSpeaker = (deviceId: string, audioElement: any, zg: any) => {
  if (deviceId) {
    try {
      zg.useAudioOutputDevice(audioElement, deviceId);
      audioElement?.play();
    } catch (error) {
      console.error('Detect speaker fail:', error);
    }
  }
};

export const selectCamera = async (deviceId: string, localStream: any, zg: any) => {

  if (deviceId && localStream && zg) {
    try {
      console.log("Device", deviceId, "Stream", localStream, "ZG", zg);
      let val = await zg.useVideoDevice(localStream, deviceId);
      let val2 = await zg.updatePublishingStream(localStream, 0);
      console.log("CAMERA_SELECT_DBG", val, val2)
    } catch (error) {
      console.error('Detect camera fail:', error);
    }
  }
}

export const  selectMuteCamera = async (cameraStatus: boolean, zgLocStream: any, zg: any) => {
  
  //zg.enableVideoCaptureDevice(zgLocStream, !cameraStatus);
  let val = await zg.mutePublishStreamVideo(zgLocStream, cameraStatus);
  console.log("#@!", val)
  let val2 = await zg.updatePublishingStream(zgLocStream, 0);
  console.log("#@!2", val2)
//  if (cameraStatus){zgLocStream.stopCaptureVideo();}
//  else if (!cameraStatus) {zgLocStream.playVideo(localVideoRef);}
}

export const checkSystemRequirements = async (zg: any) => {
  console.log('sdk version is', zg.getVersion());
  try {
    const result = await zg.checkSystemRequirements();

    console.warn('CheckSystemRequirements ', result);

    if (!result.webRTC) {
      console.error('Browser is not support webrtc!!');
      return false;
    } else if (!result.videoCodec.H264 && !result.videoCodec.VP8) {
      console.error('Browser is not support H264 and VP8');
      return false;
    } else if (!result.camera && !result.microphone) {
      console.error('Camera and microphones not allowed to use');
      return false;
    } else if (result.videoCodec.VP8) {
      if (!result.screenSharing) console.warn('Browser is not support screenSharing');
    } else {
      console.log('不支持VP8，请前往混流转码测试');
    }
    return true;
  } catch (err) {
    console.error('checkSystemRequirements', err);
    return false;
  }
}
