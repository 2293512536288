import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {AuthContext, UserContext} from 'context/';
import { useLoginSignUp } from 'hooks/useServerQueries';
import {
  FormTitle,
  Label,
  ErrorMessage,
  AuthErrorModal, SecondaryButton, PrimaryButton, LabelInput
} from '../../components-library/index.module';
import { FormWrapper } from '../../wrappers/index.module';
import '../../../styles/globals.scss';

export default function RegisterModal() {
  const { mutateAsync: LoginSignUp } = useLoginSignUp();
  const { sessionId,sessionIdFromParams } =
    useContext(UserContext);
  const {
    setAuthToken, setAuthStep
  } = useContext(AuthContext);
  const [sessionIdFromLS, setSessionIdFromLS] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [toggleTerms, setToggleTerms] = useState(false);
  const [emailEmptyError, setEmailEmptyError] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate()
  const userEmail = localStorage.getItem('userEmail')


  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      termAccepted: false,
    },
    onSubmit: async (values) => {
      const {
        confirmPassword,
        firstName,
        lastName,
        password,
        termAccepted,
      } = values;

      const payload = {
        email: userEmail,
        sessionId,
        newPassword: password,
        firstName,
        lastName,
        solution: 'RP',
        termAccepted,
      };
      const res = await LoginSignUp(payload);

      if (res.status === 200) {
        setAuthToken(res.token);
        if (sessionIdFromParams || sessionIdFromLS) {
          if (res.token){
            setShowSuccessMessage(true);
            setTimeout(() => navigate(`/checkSound`), 2000);
          }
        }
        else navigate(`/checkYourEmail`);
      }
      if (res.status == 401) setErrorMessage(res.description);
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required('First Name is required'),
      lastName: yup
        .string()
        .required('Last Name is required'),
      password: yup
        .string()
        .test("isValidPass", "", function (value: string | undefined, context)  {
          let conditions = [false, false, false, false, false], validConditions = 0;
          const numberOfMustBeValidConditions = 5;
          if (value !== undefined) {
            const hasLength = (value.length) >= 8
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSymbole = /[!@#%&]/.test(value);
            conditions = [hasLength, hasLowerCase, hasUpperCase, hasSymbole, hasNumber];
            conditions.forEach((condition) => {
              if (condition) {
                validConditions++;
              }
            });
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return context.createError({
              message: conditions.toString(),
              path: "password", // Fieldname
            })
          }
        }),
      confirmPassword: yup
        .string()
        .test("isValidPass", "", function (value: string | undefined, context) {
          if (value !== undefined) {
            if (formik.values.password !== value) {
              return context.createError({
                message: 'Passwords isn\'t matching',
                path: "confirmPassword", // Fieldname
              })
            }
            return true
          }
        }),
      termAccepted: yup
        .bool()
        .oneOf([true], 'You need to accept our Terms and Conditions.')
        .required()
        .default(false),
    }),
  });

  const toggleTermsFunction = () => {
    setToggleTerms(!toggleTerms)
  }

  useEffect(() => {
    const sessionId: string | null = localStorage.getItem('sessionId');
    if (sessionId) { setSessionIdFromLS(sessionId); }
    console.log('userEmail', userEmail)
    if (!userEmail) {
      setEmailEmptyError('Please follow the link in your email to continue registration.')
      setTimeout(() => {
        setAuthStep('')
      },10000)
    }
  }, []);

  return (
    <FormWrapper>
      {!userEmail ?
        <p className="text-[#DDD8D8] text-xl leading-[23px] font-[500] text-center pb-[40px] pt-[20px]">{emailEmptyError}</p> :
        <form onSubmit={formik.handleSubmit}>
          {!toggleTerms ? <FormTitle text="Create Remoto Account" /> : <FormTitle text="Terms and Conditions" />}
          <div className="border-b border-[#48485C] py-6 px-12">
            {!toggleTerms ?
              <>
                <div className="mb-4">
                  <LabelInput
                    textLabel="First Name"
                    name="firstName"
                    type="text"
                    value={formik.values.firstName}
                    onChange={formik.handleChange} />
                </div>
                <div className="mb-4">
                  <LabelInput
                    textLabel="Last Name"
                    name="lastName"
                    type="text"
                    value={formik.values.lastName}
                    onChange={formik.handleChange} />
                </div>
                {userEmail &&
                  <div className="mb-4">
                    <Label text="Email" />
                    <p
                      className="text-gray-300 font-[200] appearance-none bg-[#313139] rounded-lg w-full py-4 text-sm leading-tight focus:outline-none focus:shadow-outline">
                      {userEmail}
                    </p>
                  </div>
                }
                <div className="mb-4">
                  <LabelInput
                      textLabel="Password"
                      name="password"
                      type="password"
                      className="shadow-lg text-gray-300 font-[200] appearance-none bg-[#313139] rounded-lg w-full py-4 px-3 text-sm leading-tight focus:outline-none focus:shadow-outline"
                      style={{ boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.25)' }}
                      value={formik.values.password}
                      onChange={formik.handleChange} />
                </div>
                <div className="mb-4">
                  <LabelInput
                      textLabel="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      className="shadow-lg text-gray-300 font-[200] appearance-none bg-[#313139] rounded-lg w-full py-4 px-3 text-sm leading-tight focus:outline-none focus:shadow-outline"
                      style={{ boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.25)' }}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                    />
                  {formik.errors.password && <AuthErrorModal error={formik.errors.password} />}
                </div>
              </>
            : <>
              <div className='rounder-md p-5 rounded-md border-color-grey bg-[#313139] shadow-terms-container mb-4'>
                <iframe 
                src ={`https://cdn.remotopro.io/web-assets/documents/TermsofService042024.html`}
                width = '100%'
                height = '400px'
                /> 
              </div>
            </>
          }
          <div>
            {(formik.errors.firstName ||
              formik.errors.lastName ||
              formik.errors.confirmPassword ||
              formik.errors.termAccepted ||
              errorMessage) && (
              <ErrorMessage
                text={formik.errors.firstName ||
                  formik.errors.lastName ||
                  formik.errors.confirmPassword ||
                  formik.errors.termAccepted ||
                  errorMessage} />
            )}
          </div>
          <div className="flex flex-row">
            <label style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
              <input
                type="checkbox"
                className="mr-2"
                name="termAccepted"
                onChange={formik.handleChange}
              />
              I have read and agree to the Remoto<button type="button" onClick={toggleTermsFunction} className='text-[#FB6520]'>Terms and Conditions</button>.
            </label>
          </div>
          </div>
          <div className="flex flex-row justify-end w-full py-6 px-12">
            <SecondaryButton
              text='Cancel'
              click={() => setAuthStep('')}
              styles="mr-4"
            />
            <PrimaryButton
              text='Send'
              styles="bg-gradient-to-t from-[#fb6520b8] to-[#fb6520db] hover:bg-[#FB6520] ease-in-out duration-200 cursor-pointer text-[17px] text-white py-2 px-6 shadow-primary-button rounded-[8px]"
            />
          </div>
          {showSuccessMessage && (
                <div className="success-message">Submitted</div> // Place the success message here
                )}
        </form>
      }
    </FormWrapper>
  );
}
