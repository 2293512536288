import React, {
  useState,
  useEffect,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { axiosConfig } from 'utils/axios';
import { UserContext, StreamContext } from 'context/';

type StreamContextProviderWrapperProps = { children: ReactNode };

function StreamContextProviderWrapper({
  children,
}: StreamContextProviderWrapperProps) {
  const [streamId, setStreamId] = useState<string | null>(null);
  const [streamName, setStreamName] = useState<string | null>(null);
  const [streamState, setStreamState] = useState<any>();
  const [zegoCloudCred, setZegoCloudCred] = useState<any | null>(null);
  const [dolbyStreamingConfig, setDolbyStreamingConfig] = useState<any>(null);
  const [organizer, setOrganizer] = useState<any>(null);
  const [roomId, setRoomId] = useState<any>(null);
  const [sessionData, setSessionData] = useState<any>(null);
  const [roomData, setRoomData] = useState<any>(null);
  const [microphoneDevices, setMicrophoneDevices] = useState<any>(null);
  const [speakerDevices, setSpeakerDevices] = useState<any>(null);
    const [cameraDevices, setCameraDevices] = useState<any>(null);
  const [speakerDeviceSelected, setSpeakerDeviceSelected] = useState<any>(null);
  const [microphoneDeviceSelected, setMicrophoneDeviceSelected] = useState<any>(null);
  const [cameraDeviceSelected, setCameraDeviceSelected] = useState<any>(null);
  const [muteMicrophone, setMuteMicrophone] = useState<any>(null);
  const [muteCamera, setMuteCamera] = useState<any>(null);
  const [audioElement, setAudioElement] =useState<any>(null);
  const [localStream, setLocalStream] = useState<any>(null);
  const [streamIDs, setStreamIDs] = useState<any>(null); 
  const [localVideoRef, setLocalVideoRef] = useState<any>(useRef(null));
  const [remoteStreams, setRemoteStreams] = useState<any>([]);
  const [muteCallOutput, setMuteCallOutput] = useState<any>(false);
  const [muteStreamOutput, setMuteStreamOutput] = useState<any>(false);
  const [callOutputLevel, setCallOutputLevel] = useState<any>(.5);
  const [callOutputGain, setCallOutputGain] = useState<any>(.5);
  const [streamOutputLevel, setStreamOutputLevel] = useState<any>(.5);
  const [streamOutputGain, setStreamOutputGain] = useState<any>(.5);
  


  const [remoteCameraStatus, setRemoteCameraStatus] = useState<any>({});
  const [remoteMicStatus, setRemoteMicStatus] = useState<any>({});
  const [remoteSpeakerStatus, setRemoteSpeakerStatus] = useState<any>({});


  const values: any = useMemo(
    () => ({
      setStreamId,
      streamId,
      setStreamName,
      streamName,
      setStreamState,
      streamState,
      setZegoCloudCred,
      zegoCloudCred,
      setDolbyStreamingConfig,
      dolbyStreamingConfig,
      setOrganizer,
      organizer,
      setRoomId,
      roomId,
      setSessionData,
      sessionData,
      setRoomData,
      roomData,
      setMicrophoneDevices,
      microphoneDevices,
      setSpeakerDevices,
      speakerDevices,
        setCameraDevices,
        cameraDevices,
      setSpeakerDeviceSelected,
      speakerDeviceSelected,
      setMicrophoneDeviceSelected,
      microphoneDeviceSelected,
    setCameraDeviceSelected,
    cameraDeviceSelected,
      setMuteMicrophone,
      muteMicrophone,
      setMuteCallOutput,
      muteCallOutput,
      setMuteStreamOutput,
      muteStreamOutput,
      setCallOutputLevel,
      callOutputLevel,
      setCallOutputGain,
      callOutputGain,
      setStreamOutputLevel,
      streamOutputLevel,
      setStreamOutputGain,
      streamOutputGain,
      
        setMuteCamera,
        muteCamera,
      setAudioElement,
      audioElement,
      setLocalStream,
      localStream,
      setStreamIDs,
      streamIDs,
      localVideoRef,
      setRemoteStreams, 
      remoteStreams,
      setRemoteCameraStatus,
      remoteCameraStatus,
      setRemoteMicStatus,
      remoteMicStatus,
      setRemoteSpeakerStatus,
      remoteSpeakerStatus
    }),
    [
      streamId,
      streamName,
      streamState,
      zegoCloudCred,
      dolbyStreamingConfig,
      organizer,
      roomId,
      sessionData,
      roomData,
      microphoneDevices,
      speakerDevices,
      cameraDevices,
      speakerDeviceSelected,
      microphoneDeviceSelected,
      cameraDeviceSelected,
      muteMicrophone,
      muteCamera,
      audioElement,
      localStream,
      streamIDs,
      localVideoRef,
      remoteStreams,
      muteCallOutput,
      muteStreamOutput,
      callOutputLevel,
      callOutputGain,
      streamOutputLevel,
      streamOutputGain,
      remoteCameraStatus,
      remoteMicStatus,
      remoteSpeakerStatus
    ],
  );





  return (
    <StreamContext.Provider value={values}>{children}</StreamContext.Provider>
  );
}

export default StreamContextProviderWrapper;
