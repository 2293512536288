import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {AuthContext, UserContext} from 'context';
import { Header } from 'ui/components/index.module';
import { useLogout } from 'hooks/useServerQueries';

export default function Logout() {
  const { mutateAsync: Logout } = useLogout();
  const {
    setSessionId,
    setSessionIdFromParams,
  } = useContext(UserContext);
  const {
    authToken,
    setAuthToken,
    setAuthStep,
  } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(async () => {
    setAuthStep('');
    setAuthToken(null);
    //setSessionId(null);
    //setSessionIdFromParams(undefined);
    localStorage.removeItem('token');
    console.log("#REMOVE_SESSIONID_2")
    localStorage.removeItem('sessionId');
    localStorage.removeItem('userEmail');
    await Logout({ AccessToken: `${authToken}`, solution: 'RP' });
    navigate('/auth');
    }, 6000);
  }, []);

  return (
    <>
      <Header />
      <div className="flex flex-col justify-center items-center w-screen h-[calc(100vh-68px)]">
        <div className="mb-14 rounded-lg overflow-hidden">
          <img
            src="/logo/Loading-FullLogo.gif"
            alt="logo"
            width={130}
            height={130}
          />
        </div>
        <p className="text-center font-light text-4xl leading-normal mb-14">
          Thanks for using Remoto Playback, you will be redirected to the login page.
        </p>
        <p className="text-center text-base">
          &#xa9;2023 Remoto Pro, LLC. All Rights Reserved
        </p>
      </div>
    </>
  );
}
