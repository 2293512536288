import {Director, Media, View} from "@millicast/sdk";
import {MutableRefObject} from "react";
import {useEffect, useContext} from "react";
export const millicast = async (dolbyConfig: any, videoEl:  MutableRefObject<any>, setStreamState: (state: string) => void) => {
    // const {setStreamState} = useContext(StreamContext);
    const streamName = dolbyConfig?.streamName;
    console.log("$43", dolbyConfig)
    // Create callback to generate a new token
    const tokenGenerator = () =>
        Director.getSubscriber({
            streamName: dolbyConfig?.streamName,
            streamAccountId: dolbyConfig?.accountId,
            subscriberToken: dolbyConfig?.token, // Optional: This token is needed if you're subscribing to a secure stream,
            // This token should be provided by the publish owner.
        });
    
    // // Create Millicast instance
    const view = new View(streamName, tokenGenerator);
    const handleStreamState = (state: string) => {
        console.log("Stream state:", state);
        setStreamState(state);
    };
    view.on('track', (event) => {
        console.log('track', event)

        addStreamToYourVideoTag(event.streams[0]);
        return (event.streams[0])
    });
    view.webRTCPeer?.on('stats', (stats) => {
        console.log("Stats from event: ", stats);   
    })

    view.on('broadcastEvent', (event) => {
        const { name, data } = event;
        switch (name) {
          // There is a new active source
          case 'active':
            // activeSources.add(data.sourceId);
          
            if (data.sourceId !== null){
            console.log('Active Stream.', data.sourceId);
            //setStreamState('Playing');
            }
            break;
          // A source became inactive
          case 'inactive':
            // activeSources.delete(data.sourceId);
            // if (activeSources.size === 0) {
              console.log('incactive Stream');
              //setStreamState('Paused');
            // }
            break;
        case 'stopped':
            console.log('Stream stopped');    
            //setStreamState('Stopped');
            break;

          default:
            break;
        }
     
    });


    function handleBroadcastEvent(name: string, setStreamState: (state: string) => void) {
        switch (name) {
            case 'active':
                setStreamState('Playing');
                break;
            case 'inactive':
                setStreamState('Paused');
                break;
            case 'stopped':
                setStreamState('Stopped');
                break;
        }
    }

    function addStreamToYourVideoTag(elem: MediaStream) {
        console.log('elem', elem)
        if (elem.getAudioTracks().length === 0) {
            console.error("No audio tracks in the stream");
            return
        } else {
            console.log('Audio Track:', elem.getAudioTracks()[0]);  // Log the audio track if available
        }
        if (elem){
        
        videoEl.current.srcObject = elem;
        console.log('videoEl', videoEl.current.srcObject)
        videoEl.current.autoplay = true;
        videoEl.current.controls = false;
        }
    }
    type Event = 'active' | 'inactive' | 'stopped';
    const events: Event[] = ['active', 'inactive', 'stopped'];

    const options = {
        events: events,
        disableVideo: false, // Enable video
        disableAudio: false, // Enable audio
        bandwidth: 0, // Unlimited bandwidth
    };

    try {
        await view.connect(options);
        
        // console.log('connected')
    } catch (e) {
        console.error('Connection failed, handle error', e);
        view.reconnect();
    }
}
