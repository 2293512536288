import Participants from 'ui/components/Participants';
import { useNavigate } from 'react-router-dom';
import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import { StreamContext, SocketContext, UserContext, InterfaceContext } from 'context';
import { useConnectStream } from 'hooks/useServerQueries';
import RemoteVideoComponent from './remoteVideoComponent';
import { IZegoCloudStreamObj, IParticipant } from 'types';
import { get } from 'http';
import { useGetCurrentUser } from 'hooks/useServerQueries';
import { selectMicrophone, selectMuteMicrophone, selectMuteCamera, selectSpeaker, selectCamera} from 'utils/zegoCloud';
import { IParticipantSocket } from 'types/participants';
import { log } from 'console';
import { useChatConnection } from 'hooks/useServerQueries';
import { useGetParticipants } from 'hooks/useServerQueries';
import { useGetCurrentOptions } from 'hooks/useServerQueries';
import AudioLevelMeter from 'ui/components-library/AudioLevelMeter';
interface ZegoParentProps {
  // Define your prop types here
}

function ZegoParent(props) {
  //const localVideoRef = useRef(null);
  const {
    localVideoRef,
    localStream,
    setLocalStream,
    sessionData,
    zegoCloudCred,
    roomId,
    roomData,
    setRoomData,
    speakerDeviceSelected,
    microphoneDeviceSelected,
    cameraDeviceSelected,
    muteMicrophone,
    muteCamera,
    setStreamState,
    setAudioElement,
    dolbyStreamingConfig,
    setMuteMicrophone,
    muteCallOutput,
    setMuteCallOutput,
    setRemoteCameraStatus,
    remoteCameraStatus,
    setRemoteMicStatus,
    remoteMicStatus,
    setRemoteSpeakerStatus,
    remoteSpeakerStatus,
    setDolbyStreamingConfig } = useContext(StreamContext);
  const email = localStorage.getItem('userEmail');

  const [isLocalStreamInitialized, setIsLocalStreamInitialized] = useState(false);
  //const [currentUserData, setCurrentUserData] = useState(null);
  const [doCapture, setDoCapture] = useState(true);
  const { participants, socketData, setParticipants } = useContext(SocketContext);
  const navigate = useNavigate();
  const [curUserData, setCurUserData] = useState<any>(null);
  const { sessionIdFromParams, userId, setUserId } = useContext(UserContext);
  const { mutateAsync: connectStream } = useConnectStream(sessionIdFromParams);
  const { isListOfParticipants } = useContext(InterfaceContext);
  const { mutateAsync: CurrentUser } = useGetCurrentUser()
  const [loggedInZg, setLoggedInZg] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [remoteStreamInfo, setRemoteStreamInfo] = useState<any>([]);
  const parent_remote_audioRef = useRef(null);
  const {mutateAsync: GetCurrentOptions } = useGetCurrentOptions(sessionIdFromParams);
  const { mutateAsync: chatConnection } = useChatConnection(sessionIdFromParams);
  const {mutateAsync: getParticipants } = useGetParticipants(roomId);
  async function handleTokenWillExpire() {
    console.log(`Token for room ${roomId} is about to expire. Renewing now...`);
       // Get a new token
    const chatData = await chatConnection(sessionIdFromParams);
    let data = {...roomData, zegoCloud: {...chatData.zegoCloudConfig}}
    setRoomData(data);
 
  }
  const getCurrentOptions = async () => {
    const data = await GetCurrentOptions(sessionData?.id);
    if (data) {
      console.log("current stream state:", data)
      switch (data.streamControlStatus) {
        case "STARTED":
          setStreamState("Playing");
          break;
        case "STOPPED":
          setStreamState("Stopped");
          break;
        default:
          break;
      }
    
      //setShowMainStream(data);
    }
  };
  async function handleParticipantsUpdate() {
    console.log(`Token for room ${roomId} is about to expire. Renewing now...`);
       // Get a new token
    const obj = await getParticipants(roomId);
    const array = Object.keys(obj)
    .filter(key => key !== 'status') // Filter out the 'status' key
    .map(key => obj[key]); // Map each remaining key to its value
    setParticipants(array) 
    // setParticipants(participants)
    console.log("PARTICIPANTS_UPDATER", participants)
    
  }

  const zegoCloudEvents = (zg: any) => {

    zg.on('tokenWillExpire', handleTokenWillExpire)


    zg.on('roomStateUpdate', (roomID: any,
      state: any,
      errorCode: any,
      extendedData: any) => {
      if (state == 'DISCONNECTED') {
        console.log('roomStateUpdate DISCONNECTED')
      }

      if (state == 'CONNECTING') {
        console.log('roomStateUpdate CONNECTING')
      }

      if (state == 'CONNECTED') {
        console.log('roomStateUpdate CONNECTED')
      }
    })

    zg.on('remoteCameraStatusUpdate', (streamID: any, status: any) => {
      if (remoteStreamInfo.some((stream:any) => stream.streamID === streamID)) {
        setRemoteCameraStatus((prevStatus:any ) => ({
          ...prevStatus,
          [streamID]: (status !== 'MUTE'),
        }));
      }
    });
    zg.on('remoteMicStatusUpdate', (streamID: any, status: any) => {
      if (remoteStreamInfo.some((stream:any) => stream.streamID === streamID)) {
        setRemoteMicStatus((prevStatus:any ) => ({
          ...prevStatus,
          [streamID]: (status !== 'MUTE'),
        }));
      }
    });
    
      


    
    


    zg.on('roomStreamUpdate', async (roomID: any, updateType: any, streamList: any) => {
      let _outStreams: any[] = []
      if (updateType === 'ADD') {
        console.log("STREAM_LIST", streamList)
        let outStreams = remoteStreamInfo;
        streamList.forEach((stream: any) => {
          // Create a new stream object and add it to the state
          console.log("NEW_REMOTE_STREAM", stream)
          console.log("EXISTING_STREAMS", remoteStreamInfo)


          if (remoteStreamInfo.map((stream: any) => stream.streamID).includes(stream.streamID)) {
            console.log("STREAM_ALREADY_EXISTS")
          }
          else {
            outStreams.push(stream)
          }

          console.log("REMOTE_STREAMS", outStreams)

        });
        _outStreams = outStreams
      }
      else if (updateType === 'DELETE') {
        // Create a set of stream IDs to be removed
        const streamIDsToRemove = new Set(streamList.map((stream: any) => stream.streamID));
        console.log("STREAMS_TO_REMOVE", streamIDsToRemove)
        // Use functional state update to filter out streams
        let val = (prevStreams: any) =>
          prevStreams.filter((stream: any) => !streamIDsToRemove.has(stream.streamID));
        _outStreams = val(remoteStreamInfo)
        console.log("REMOTE_STREAMS_OUT", _outStreams)


      }

      const uniqueRemoteStreams = Array.from(new Set(_outStreams.map((stream: any) => stream.streamID)))
        .map((streamID: any) => _outStreams.find((stream: any) => stream.streamID === streamID));
      setRemoteStreamInfo(uniqueRemoteStreams);
      if (uniqueRemoteStreams.length - 1 != participants.length) {
        handleParticipantsUpdate()
      }
      console.log("UNIQUE_REMOTE_STREAMS", uniqueRemoteStreams)
    });



    zg.on(
      'roomUserUpdate',
      async (
        roomID: any,
        updateType: any,
        streamList: any,
        extendedData: any
      ) => {
        console.log('roomUserUpdate')
      }
    )

    zg.on('playerStateUpdate', async (result: any) => {
      console.log('playerStateUpdate ', result)
    })
  }

  const loginRoom = async (zg: any, roomId: any, token: any, userId: any) => {
    try {
      const result = await zg.loginRoom(
        roomId,
        token,
        { userID: userId },
        { userUpdate: true }
      );
      if (result === true) {
        console.log('zego cloud login success');
        return true;
      }
    } catch (error) {
      console.log('error ', error);
      return false;
    }
  };
  async function getCurUser() {
    const res = await CurrentUser();
    if (res) {
      setCurUserData(res)
      setUserId(res.id)
    }
    console.log("####", res)

  }

  const startPublishingStream = async (deviceID: any, cameraStatus: any, zg: any, publishID: any, videoRef: any) => {
    if (zg) {

      let cam_options: any = false;
      console.log("DEVICE_ID", deviceID)
      //const localView = await zg?.createLocalStreamView(localStream);
      if (deviceID) {
        cam_options = {
          facingMode: 'user',
          width: 320,
          height: 180,
          frameRate: 15,
        }
      }

      const V_options = {
        camera: {
          audio: true,
          video: cam_options,
          AEC: true,
          ANS: true,
          AGC: true,
          channelCount: 2,
        }
      };




      const localStream: any = await zg.createZegoStream(V_options);




      videoRef.innerHTML = '';
      videoRef.style.borderRadius = '5px'
      videoRef.style.overflow = 'hidden'
      videoRef.style.height = 'stretch'

      localStream.playCaptureVideo(videoRef);

      console.log("LOCAL_STREAM_INNER", localStream, publishID)
      let val = await zg.startPublishingStream(publishID, localStream);
      console.log("LOCAL_STREAM_OUTER", val)
      if (deviceID) {
        await zg.useVideoDevice(localStream, deviceID);
        await zg.updatePublishingStream(localStream, 0);
      }
      return localStream;


    }
  };

  const logoutRoom = async (zg: any, roomId: any, publishId: any, localStream: any) => {
    localStream.stopCaptureVideo();
    await zg.stopPublishingStream(publishId);
    await zg.logoutRoom(roomId);
  }
  



  const zegoCloud = async (localVideoRef: HTMLElement | null, currentUserData: any) => {
    const { zg } = zegoCloudCred;
    const player: HTMLElement | null = document.querySelector('#music-player');
    //const remoteUsersBlock = document.querySelector('#videoRemoteUsers')


    console.log("LOC_USER", currentUserData)


    const publishID = `stream-${currentUserData.id}`

    console.log("LOC_INIT", isLocalStreamInitialized)
    console.log("LOC_CAMERA", cameraDeviceSelected)
    console.log("LOC_PUBLISH", publishID)

    if (!isLocalStreamInitialized && (publishID !== 'stream-undefined')) {

      const zegoCloudStreamObj = await startPublishingStream(cameraDeviceSelected, muteCamera, zg, publishID, localVideoRef);
      console.log("ZEGO_CLOUD_STREAM_OBJ", zegoCloudStreamObj)
      setLocalStream(zegoCloudStreamObj)



      if (zegoCloudStreamObj && !isLocalStreamInitialized) {

        console.log("LOCAL STREAM", zegoCloudStreamObj)
       
        //setAudioElement(player);
        


        //if (speakerDeviceSelected) selectSpeaker(speakerDeviceSelected, player, zg)
        if (microphoneDeviceSelected) selectMicrophone(microphoneDeviceSelected, zegoCloudStreamObj.localStream, zg);
        if (cameraDeviceSelected) selectCamera(cameraDeviceSelected, zegoCloudStreamObj.localStream, zg);
        selectMuteMicrophone(muteMicrophone, zg)
        selectMuteCamera(muteCamera, zegoCloudStreamObj, zg)


      }
    }
    return (true)
  }

  useEffect(()=>{
    console.log("LOC_USER", curUserData)
    console.log('PARTICIPANTS', participants)
    console.log("REMOTE_STREAMS", remoteStreamInfo)
  }, [participants, remoteStreamInfo])

  

  useEffect(() => {
    if (!socketData) return;

    const handleMessage = (event: any) => {
      const eventData = JSON.parse(event.data);
      console.log("EVENT_DATA", eventData);

      switch (eventData.type) {
        case 'ROOM_CLOSED':
          console.log("ROOM_CLOSED", eventData.roomCloseReason);
          navigate('/logout');
          break;
        case 'USER_JOINED_ROOM':
        case 'USER_LEFT_ROOM':
        case 'USER_DISCONNECTED_ROOM':
        case 'PARTICIPANTS_REMOVED':
          handleParticipantsUpdate();
          if (eventData.type === 'PARTICIPANTS_REMOVED' && eventData.userIds.includes(userId)) {
            navigate('/logout');
          }
          break;
        case 'USERS_AUDIO_IO_UPDATED':
          if (curUserData && eventData.userIds.includes(curUserData.id)) {
            if (eventData.muted !== undefined) {
              console.log('MUTE', eventData.muted);
              selectMuteMicrophone(eventData.muted, zegoCloudCred.zg);
              setMuteMicrophone(eventData.muted);
            }
            if (eventData.deafened !== undefined) {
              console.log('DEAFEN', eventData.deafened);
              setMuteCallOutput(eventData.deafened);
            }
          }
          break;
        case 'STREAM_CONTROL_SETTING_CHANGED': {
            if (eventData.streamControl.action === "STOP"){
                getCurrentOptions();
                console.log('paused stream');
                break
            }
            else if (eventData.streamControl.action === "START"){
                getCurrentOptions();
                console.log('started stream');
                break
            }
          
          break;
        }
        default:
          break;
      }
    };

    socketData.onmessage = handleMessage;

    return () => {
      socketData.onmessage = null;
    };

  }, [socketData, participants, curUserData]);

  useEffect(() => {
    const login = async () => {
      if (!loggedInZg) {
        if (zegoCloudCred && roomId && sessionData) {
          try {
            const val = await loginRoom(zegoCloudCred.zg, roomId, zegoCloudCred.token, zegoCloudCred.userId);
            console.log("ZG_LOGIN_RESPONSE", val);

            setLoggedInZg(Boolean(val));
          } catch (error) {
            console.error("Error logging in:", error);
          }
        } else {
          console.log("FAIL_LOGIN_ZG")
        }
      }
    };    zegoCloudEvents(zegoCloudCred.zg);
    login();

    return () => {
      zegoCloudCred.zg.stopPublishingStream(`stream-${userId}`)
      zegoCloudCred.zg.logoutRoom(roomId);
      zegoCloudCred.zg.destroyEngine();
      zegoCloudCred.zg = null;
    };
  }, []);

  useEffect(() => {

    //setCurrentUserData(sessionData?.participants.filter((item: IParticipant) => item.email === email && item)[0]);
    console.log("CURRENT_SESSION_DATA", sessionData)
    if (!curUserData) {
      console.log("FAIL_CURRENT_USER_DATA", curUserData)
      getCurUser()

    }
    if (!roomId) {
      console.log("FAIL_ROOM_ID", roomId)
      alert("No room id, please try again")
      //handle no room id
    }
    if (roomId && curUserData && loggedInZg) {
      console.log("@_SUCCESS", roomId, curUserData, cameraDeviceSelected)
      //curUserData, roomId and cameraDeviceSelected exist
      setTimeout(()=>{setIsLoaded(true)}, 1000);

    }

    // return (
    //   log out of zego
    // );
  }, [sessionData, curUserData, roomId, cameraDeviceSelected, participants, remoteStreamInfo, loggedInZg]);


  useEffect(()=>{
    if (isLoaded) {
      console.log("LOADED")
      zegoCloud(localVideoRef.current, curUserData).then((result) => {
        setIsLocalStreamInitialized(result);

      })

    }
  }, [isLoaded])
  // useEffect(() => {
  //   console.log("PARTICIPANTS_##", participants)
  //   zegoCloudCred.zg.updatePublishingStream(localStream, 0)

  // }, [cameraDeviceSelected, muteCamera, localStream, remoteStreams])

  const findParticipantIndex = (remoteStream: any) => {
    // Extract userId from the streamID
    const userId = remoteStream.streamID.split('-')[1];

    // Find the index of the participant with this userId
    const index = participants.findIndex((participant: any) => participant.userId === userId);

    if (index === -1) { console.log('Participant not found'); return null }
    else
      return index; // This will be -1 if no matching participant is found
  };

  return (

    <div style={{ width: 'stretch', height: 'stretch' }}>
      {/* <Participants/> */}
      {isLoaded && <>
        <div className={`${isListOfParticipants ? "h-full" : "hidden"}`} style={{ margin: '20px 0px 20px 20px', width: 'fit-content', display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
          
            <div id="localVideo" ref={localVideoRef}></div>
            <div style = {{display: 'flex', paddingLeft: '5px', paddingRight: '5px', transform: 'translate(0px, -38px)', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'row', width: 'stretch', marginBottom: '-38px'}}>
            <div style={{ zIndex: '10', textShadow: '2px 2px 2px black', transform: "translate(0px, 5px)"}}>
              {`${curUserData?.firstName ?? "Guest"} ${curUserData?.lastName ?? ""}`}
            </div>
            <div style={{ zIndex: '10', textShadow: '2px 2px 2px black', marginBottom: '2px' }}>
              {muteMicrophone ?
                <img src="/icons/micOff.svg" alt="muted"
                style={{ width: '33px', height: '33px', padding: '2px', backgroundColor: "#3f3f3f", borderRadius: "100px",  border: '2px solid #FB6520' }} /> : 
                <img src="/icons/micOn.svg" alt="unmuted" 
                style={{ width: '33px', height: '33px', padding: '3px', backgroundColor: "#3f3f3f", borderRadius: "100px",  border: '2px solid #2DBF81' }} /> }
            </div>
            {/* {localVideoRef.current && <div> <AudioLevelMeter stream={localVideoRef.current.srcObject as MediaStream} /> </div>} */}

          </div>

          {remoteStreamInfo?.map((remoteStream: any) => (
            <div key={remoteStream.streamID}>
              <RemoteVideoComponent
                key={remoteStream.streamID}
                stream={remoteStream}
                zegoCloudCred={zegoCloudCred}
                participantInfo={participants[findParticipantIndex(remoteStream)]}
                
              />
            </div>
          ))}
        </div>
        <div id="videoUsers">

        </div>
      </>
      }
    </div>
  );


}


export default ZegoParent;


/* Joined participants from join response ws
[
    {
        "id": "Y4AM",
        "roomId": "eQX2",
        "userId": "mmd",
        "email": "charles.danner@remotopro.io",
        "name": "Charles Danner",
        "sessionRole": "RP_ORGANIZER",
        "status": "JOINED",
        "muted": true,
        "deafened": false,
        "ipAddress": "67.247.48.37",
        "port": 0
    },
    {
        "id": "zqAQ",
        "roomId": "eQX2",
        "userId": "w2d",
        "email": "cvadbooking@gmail.com",
        "name": "Charles Tester",
        "sessionRole": "RP_GUEST",
        "client": "WEB",
        "status": "JOINED",
        "muted": true,
        "deafened": false,
        "ipAddress": "67.247.48.37",
        "port": 0
    }
]


[
  {
      "id": "zqAQ",
      "roomId": "eQX2",
      "userId": "w2d",
      "email": "cvadbooking@gmail.com",
      "name": "Charles Tester",
      "sessionRole": "RP_GUEST",
      "client": "WEB",
      "status": "JOINED",
      "muted": true,
      "deafened": false,
      "ipAddress": "67.247.48.37",
      "port": 0
  },
  {
      "id": "Y4AM",
      "roomId": "eQX2",
      "userId": "mmd",
      "email": "charles.danner@remotopro.io",
      "name": "Charles Danner",
      "sessionRole": "RP_ORGANIZER",
      "status": "JOINED",
      "muted": true,
      "deafened": false,
      "ipAddress": "67.247.48.37",
      "port": 0
  }
]
*/