import { axiosConfig as axios, wrapAxiosResponse} from 'utils/axios';

import { IApproveLogoutOthersPayload, IForgotPasswordChangePayload, IForgotPasswordSendCodePayload, IForgotPasswordResendCodePayload, ILoginPayload, ILoginSignUpPayload, ILogoutPayload } from 'types';

export const Login = async (payload?: ILoginPayload) => {
  return await wrapAxiosResponse(axios
    .post(`auth/login`, {
      ...(payload ? { ...payload } : {}),
    }))
};

export const ApproveLogoutOthers = async (payload?: IApproveLogoutOthersPayload) => {
  return await wrapAxiosResponse(axios
    .post('auth/logout/other-devices', {
      ...(payload ? { ...payload } : {}),
    })
  )
};

export const LoginSignUp = async (payload?: ILoginSignUpPayload) => {
  return await wrapAxiosResponse(axios
      .post('auth/login/password', {
        ...(payload ? { ...payload } : {}),
      }))
};

export const Logout = async (payload: ILogoutPayload) => {
  return await wrapAxiosResponse(axios
    .post('auth/logout', {
      ...(payload ? { ...payload } : {}),
    }))
};

export const ForgotPasswordSendCode = async (payload: IForgotPasswordSendCodePayload) => {
  return await wrapAxiosResponse(axios
    .put('/auth/password/reset', {
      ...(payload ? { ...payload } : {}),
    }))
};

export const ForgotPasswordChange = async (payload: IForgotPasswordChangePayload) => {
  return await wrapAxiosResponse(axios
    .post('/auth/password', {
      ...(payload ? { ...payload } : {}),
    }))
};

export const GetSessions = async () => {
  return await wrapAxiosResponse(axios
    .get('playback/sessions')
  )
};

export const GetSessionData = async (
  sessionId: string | string[] | undefined,
) => {
  return await wrapAxiosResponse(axios
    .get(`playback/sessions/${sessionId}`)
  )
};

export const ConnectRoom = async (streamId: string | string[] | undefined) => {
  return await wrapAxiosResponse(axios
    .get(`playback/sessions/${streamId}/start`)
  )
};
export const ConnectStream = async (streamId: string | string[] | undefined) => {
  return await wrapAxiosResponse(axios
    .post(`playback/sessions/${streamId}/stream/connection`)
  )
};

export const Watermark = async (sessionId: string | null) => {
  return await wrapAxiosResponse(axios.get(`/playback/sessions/${sessionId}/settings/watermark`))
}

export const WatermarkInfo = async (watermarkId: string | undefined) => {
  return await wrapAxiosResponse(axios.get(`/watermark-templates/${watermarkId}`))
}

export const GetCurrentUser = async () => {
  return await wrapAxiosResponse(axios.get('/users/profile'))
}

export const RefreshToken = async (payload: { token: string, solution: string }) => {
  return await wrapAxiosResponse(axios.post('/auth/refreshtoken', {
    ...(payload ? { ...payload } : {}),
  }))
}

export const GetParticipants = async(roomId: string) => {
  return await wrapAxiosResponse(axios.get(`/playback/room/${roomId}/participants`))
}

export const ChatConnection = async (sessionId: string | string[] | undefined,) => {
  return await wrapAxiosResponse(axios.get(`/playback/sessions/${sessionId}/chat/connection`))
}

export const GetCurrentOptions = async (sessionId: string | string[] | undefined,) => {
  return await wrapAxiosResponse(axios.get(`/playback/sessions/${sessionId}/start/options`))
}
