import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  FormInput,
  FormTitle,
  Label,
  ErrorMessage, AuthErrorModal,
  PrimaryButton,
  Subtitle16,
  LabelInput, EyeButton, SecondaryButton
} from '../../components-library/index.module';
import { FormWrapper } from '../../wrappers/index.module';
import {AuthContext, UserContext} from 'context/';
import {
  useForgotPasswordChange,
  useForgotPasswordSendCode,
} from 'hooks/useServerQueries';
import '../../../styles/globals.scss';

/**
 * * Sucess messaging after submission implemented and also delay before after button clicking to allow the user to read the message
 * * Primary button added and replacing old non component button
 * Todo: verify if the delay times are appropiate on testing phase
*/

export default function ForgetPasswordModal() {
  const [isSecond, setIsSecond] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [sessionIdFromLS, setSessionIdFromLS] = useState('');
  const [errorMessageFirstForm, setErrorMessageFirstForm] = useState('');
  const [errorMessageSecondForm, setErrorMessageSecondForm] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state for success message
  const { mutateAsync: ForgotPasswordSendCode } = useForgotPasswordSendCode();
  const { mutateAsync: ForgotPasswordChange } = useForgotPasswordChange();
  const { sessionIdFromParams } = useContext(UserContext);
  const { setAuthToken, setAuthStep } = useContext(AuthContext);
  const navigate = useNavigate();

  const formikFirst = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values: {email: string}) => {
      const { email } = values;
      const payload = {
        email,
        solution: 'RP',
      };
      const res = await ForgotPasswordSendCode(payload);
        if (res.challenge === 'CONFIRM_RESET_PASSWORD') {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
            setEmail(res.email);
            setIsSecond(true);
          }, 2000); // Display success message for 2 seconds
      } else if (res.challenge === 'LOGIN') {
        setErrorMessageFirstForm(res.message)
        setTimeout(() => {
          setAuthStep('')
          
        }, 2000); // Display success message for 2 seconds
      } else setErrorMessageFirstForm(res.description);
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Must be a valid email')
        .required('Email is required'),
    }),
  });

  const formikSecond = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      code: yup
        .string()
        .required(),
      password: yup
        .string()
        .test("isValidPass", "", function (value: string | undefined, context)  {
          let conditions = [false, false, false, false, false], validConditions = 0;
          const numberOfMustBeValidConditions = 5;
          if (value !== undefined) {
            const hasLength = (value.length) >= 8
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSymbole = /[!@#%&]/.test(value);
            conditions = [hasLength, hasLowerCase, hasUpperCase, hasSymbole, hasNumber];
            conditions.forEach((condition) => {
              if (condition) {
                validConditions++;
              }
            });
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return context.createError({
              message: conditions.toString(),
              path: "password", // Fieldname
            })
          }
        }),
      confirmPassword: yup
        .string()
        .test("isValidPass", "", function (value: string | undefined, context) {
          if (value !== undefined) {
            if (formikSecond.values.password !== value) {
              return context.createError({
                message: 'Passwords do not match',
                path: "confirmPassword", // Fieldname
              })
            }
            return true
          }
        })
    }),
    onSubmit: async (values) => {
      const { code, password, confirmPassword } = values;
      const payload = {
        email,
        confirmationCode: code,
        newPassword: password,
        solution: 'RP',
      };
      const res = await ForgotPasswordChange(payload);
      if (res.status === 200 && res.challenge === 'NONE') {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          setAuthToken(res.token);
          if (sessionIdFromParams || sessionIdFromLS) {
            setAuthStep('');
          } else {
            navigate(`/checkYourEmail`);
          }
        }, 2000); // Display success message for 2 seconds
      } else setErrorMessageSecondForm(res.description);
    },
  });

  const resendResetCode = async () => {
    try {
      const response = await ForgotPasswordSendCode({ email, solution: 'RP' });
      if (response.success) {
        setErrorMessageSecondForm('Verification Code Resent');
      } else {
        setErrorMessageSecondForm(response.description || 'Failed to resend code.');
      }
    } catch (error) {
      setErrorMessageSecondForm('An error occurred');
    }
  };
  useEffect(() => {
    const sessionId: string | null = localStorage.getItem('sessionId');
    if (sessionId !== null) setSessionIdFromLS(sessionId);
  }, []);

  return (
    <FormWrapper>
      <FormTitle text="Forgot Password" />
      {!isSecond ? (
        <form onSubmit={formikFirst.handleSubmit}>
          <Subtitle16 text={`Enter your registered email and we’ll send you an ${email} with a one-time verification code.`} />
          <div className="py-4 px-10" style={{ borderBottom: '1px solid var(--Gun-Powder)' }}>
            <LabelInput textLabel="Email" name="email" type="email" placeholder="user@example.com" value={formikFirst.values.email} onChange={formikFirst.handleChange} className="body-large-17-regular" style={{ '--body-large-text-color': 'var(--Swiss-Coffee)' }}/>
            {(formikFirst.errors.email || errorMessageFirstForm) && (
              <ErrorMessage
                text={formikFirst.errors.email || errorMessageFirstForm}
              />
            )}
          </div>
          <div className="py-4 px-10 flex justify-end">
            <SecondaryButton
              text='Back'
              click={() => setAuthStep('')}
              styles="mr-4"
            />
            <PrimaryButton
              text='Send'
              styles="bg-gradient-to-t from-[#fb6520b8] to-[#fb6520db] hover:bg-[#FB6520] ease-in-out duration-200 cursor-pointer text-[17px] text-white py-2 px-6 shadow-primary-button rounded-[8px]"
            />
          </div>
          {showSuccessMessage && (
          <div className="success-message">Verification Code Sent</div> // Display success message
          )}
        </form>
      ) : (
        <form onSubmit={formikSecond.handleSubmit}>
            <div className="border-b border-[#48485C] pt-2 pb-6 px-10">
              <Subtitle16 text={`We have send a password reset code by email to ${email}. Enter it below to reset your password.`} />
            <div className="mb-6">
              <LabelInput textLabel='Reset Code' name="code" type="text" value={formikSecond.values.code} onChange={formikSecond.handleChange} />
            </div>
            <div className="mb-6" style = {{display: 'flex', flexDirection: 'row'}}>
              
              <LabelInput
                textLabel="New Password"
                name="password"
                type='password'
                placeholder="••••••••••••••••"
                value={formikSecond.values.password}
                onChange={formikSecond.handleChange}
              />
              {formikSecond.errors.password && <AuthErrorModal error={formikSecond.errors.password} />}
            </div>
            <LabelInput
              textLabel="Confirm Password"
              name="confirmPassword"
              type='password'
              placeholder="••••••••••••••••"
              value={formikSecond.values.confirmPassword}
              onChange={formikSecond.handleChange}
            />
            {(
              formikSecond.errors.confirmPassword ||
              errorMessageSecondForm) && (
              <ErrorMessage
                text={
                  formikSecond.errors.confirmPassword ||
                  errorMessageSecondForm
                }
              />
            )}
          </div>
          <div className="py-4 px-10 flex justify-between">
          <SecondaryButton
              text='Back'
              click={() => setAuthStep('')}
              styles="mr-4"
            />
            <button
              onClick={resendResetCode}
              className='flex items-center'
            >
              Resend reset code
            </button>
            <PrimaryButton
              text='Reset Password'
              styles="bg-gradient-to-t from-[#fb6520b8] to-[#fb6520db] hover:bg-[#FB6520] ease-in-out duration-200 cursor-pointer text-[17px] text-white py-2 px-6 shadow-primary-button rounded-[8px]"
            />
          </div>
          {showSuccessMessage && (
          <div className="success-message">Password Successfully Updated</div> // Display success message
          )}
        </form>
      )}
    </FormWrapper>
  );
}
