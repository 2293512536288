import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import { Dropdown, CustomCheckbox } from '../../components-library/index.module';
import {StreamContext, SocketContext, UserContext, InterfaceContext, AuthContext} from 'context/';
import { leavePayload } from 'constants/';
import {websocketConnection, websocketOnOpen, websocketOnMessageJoin, stopPing} from "../../../utils/websocket";
import IconText from "../../components-library/IconText";
import { selectMicrophone } from 'utils/zegoCloud';
import { list } from 'postcss';
import { useGetCurrentUser } from 'hooks/useServerQueries';


export default function SessionAudioSettings() {
  const {
    sessionData,
    setStreamState,

    setRoomId,
    zegoCloudCred,
    roomData,
    setSpeakerDevices,
    setMicrophoneDevices,
    setCameraDevices,
    cameraDevices,
    setSpeakerDeviceSelected,
    speakerDeviceSelected,
    setMicrophoneDeviceSelected,
    microphoneDeviceSelected,
    setCameraDeviceSelected,
    cameraDeviceSelected,
    setMuteMicrophone,
    muteMicrophone,
    setMuteCamera,
    muteCamera,
  } = useContext(StreamContext);
  const {sessionIdFromParams } = useContext(UserContext);
  const { authToken, setAuthStep } = useContext(AuthContext);
  const { socketData, setSocketData, setParticipants } = useContext(SocketContext);
  const { toggleJoinModal } = useContext(InterfaceContext)
  let ws: WebSocket;
  const videoEl = useRef<any>(null);
  const { mutateAsync: CurrentUser } = useGetCurrentUser()
  const [microphoneDevicesLoc, setMicrophoneDevicesLoc] = useState<any>();
  const [speakerDevicesLoc, setSpeakerDevicesLoc] = useState<any>();
  const [cameraDevicesLoc, setCameraDevicesLoc] = useState<any>();
  const navigate = useNavigate();

    interface IDeviceConfig {
      deviceID: string;
      deviceName: string;
    }

    const [webCamDevices, setWebCamDevices] = useState<IDeviceConfig[]>([]);
    const [selectedWebCamDeviceId, setSelectedWebCamDeviceId] = useState<string>('');
  
    useEffect(() => {
      navigator.mediaDevices.enumerateDevices()
        .then(deviceInfos => {
          const videoDevices = deviceInfos
            .filter(device => device.kind === 'videoinput')
            .map(device => ({
              deviceID: device.deviceId,
              deviceName: device.label || 'Unnamed Device'
            }));
          setWebCamDevices(videoDevices);
          setCameraDeviceSelected(videoDevices[0].deviceID);
          console.log("WEBCAM_DEVICES", videoDevices)
          //setCameraDevices(videoDevices)
        })
        .catch(error => {
          console.error("Error accessing media devices:", error);
        });
    }, []);
  
    const handleDeviceSelection = (deviceId: string) => {
      setSelectedWebCamDeviceId(deviceId);  
      setCameraDeviceSelected(deviceId);
      console.log("SELECTED_DEVICE", deviceId)
      // Perform additional actions here if needed
    };
  




  const listOfDevices = async (zg: any) => {
    const deviceInfo = await zg?.enumDevices();
    if (deviceInfo) {
      setMicrophoneDevicesLoc(deviceInfo.microphones);
      setSpeakerDevicesLoc(deviceInfo.speakers);
      setCameraDevicesLoc(deviceInfo.cameras);
      setMicrophoneDevices(deviceInfo.microphones);
      setSpeakerDevices(deviceInfo.speakers);
      setCameraDevices(deviceInfo.cameras);
      //videoEl.current.srcObject = deviceInfo.cameras[0];
      //videoEl.current.autoplay = true;
      if (deviceInfo.speakers.length > 0 && !speakerDeviceSelected) {
      setSpeakerDeviceSelected(deviceInfo.speakers[0].deviceID);
      }
      if (deviceInfo.microphones.length > 0 && !microphoneDeviceSelected) {
      setMicrophoneDeviceSelected(deviceInfo.microphones[0].deviceID);
      }
      if (deviceInfo.cameras.length > 0 && !cameraDeviceSelected) {
      setCameraDeviceSelected(deviceInfo.cameras[0].deviceID);
      }
      // if (!selectedWebCamDeviceId && deviceInfo.cameras.length > 0){
      //   console.log("DEFAULT_CAMERA_SELECTED", deviceInfo.cameras[0].deviceID)
      
      // }
   
     
    }
  };

  const askToJoin = () => {
    if (sessionIdFromParams !== '' && roomData?.webSocketUrl) {
     
      if (authToken) {
        ws = websocketConnection(authToken, roomData);
        if (ws) {
          websocketOnOpen(ws, setSocketData, toggleJoinModal)

          websocketOnMessageJoin(ws, setAuthStep, setStreamState, setParticipants, toggleJoinModal, navigate, sessionIdFromParams)
        }
      }
    }
  };

  


  const leaveSession = () => {
    if (socketData) {
      stopPing(ws);
      
      socketData.send(JSON.stringify(leavePayload))
    };
    navigate('/logout');
  };

  const selectAudioLoc = async (deviceId: string) => {
    setSpeakerDeviceSelected(`${deviceId}`);
    };

  const selectMicrophoneLoc = (deviceId: string) => {
    setMicrophoneDeviceSelected(deviceId)
  };

  const selectCameraLoc = (deviceId: string) => {
    console.log("@#3", deviceId)
    setCameraDeviceSelected(deviceId)
  }

  const selectMuteMicrophoneLoc = (e) => {
    console.log("SELECTED_MICROPHONE" , e)
    setMuteMicrophone(!muteMicrophone)
    
  };

  const selectMuteCameraLoc = (e) => {
    setMuteCamera(!muteCamera)
  };

  async function getCurUser() {
    const res = await CurrentUser();
    setCurUserData(res);
    console.log("####", res)

  }


  useEffect(() => {
    if (zegoCloudCred) {
      listOfDevices(zegoCloudCred.zg);
    }
    console.log("@#6", cameraDeviceSelected)
    console.log("@#7", cameraDevicesLoc)
  }, [zegoCloudCred, cameraDeviceSelected]);

  function getDeviceNameById(deviceId: string, devices: any) {
    const device = devices?.find((device: any) => device.deviceID === deviceId);
    return device ? device.deviceName : 'Unknown Device';
  }

  
  return (
    <>
      {sessionData?.state !== 'CLOSED' && zegoCloudCred &&
        <div className="bg-[var(--Tuna)] rounded px-4 py-4 min-w-[680px]">
          <div className="border-b border-[var(--Mako)] border-b-[0.736px] px-4 py-4">
            <p className="subtitle-rf-16">Check your Call and Stream preferences</p>
          </div>
          <div className="flex flex-column justify-between items-start px-4 py-4 max-h-[450px]">
            <div className="pr-6">
              <h2 className="subtitle-rf-16 my-4">Camera</h2>
              <IconText text="Camera" imgSrc="/icons/camera.svg" imgAlt="camera" classesBlock="mb-6" />
              {!muteCamera &&
                  <>
                    <div style = {{width: "250px"}}>
                      <Webcam audio={false} videoConstraints={{deviceId: selectedWebCamDeviceId}} />
                      <Dropdown
                        dataDropdown={cameraDevices}
                        defaultText={getDeviceNameById(cameraDeviceSelected, cameraDevices)}
                        handle={handleDeviceSelection}
                      />
                    </div>
                  </>
              }
              <CustomCheckbox
                  text="Disable my camera when entering the session"
                  checked={muteCamera}
                  handler={selectMuteCameraLoc}
                  id = "mute-camera-checkbox"
              />
            </div>
            <div className="pl-6 min-w-[316px]  border-l border-[var(--Mako)]">
                <h2 className="subtitle-rf-16 my-4">Audio</h2>
                <div className="flex flex-col justify-center min-h-[160px]">
                  <div className="flex justify-between mb-4">
                    <IconText text="Microphone" imgSrc="/icons/micOn.svg" imgAlt="microphone" />
                    <Dropdown
                      dataDropdown={microphoneDevicesLoc}
                      defaultText={getDeviceNameById(microphoneDeviceSelected, microphoneDevicesLoc)}
                      handle={selectMicrophoneLoc}
                    />
                  </div>
                  <CustomCheckbox
                    text="Mute my microphone when entering the session"
                    checked={muteMicrophone}
                    handler={selectMuteMicrophoneLoc}
                    id="mute-microphone-checkbox"


                  />
              </div>

              <div className="flex items-center border-t border-[var(--Mako)] py-4 min-h-[140px]">
                <div className="flex justify-between w-full">
                  <IconText text="Speakers" imgSrc="/icons/headphones.svg" imgAlt="headphones" />
                  <Dropdown
                    dataDropdown={speakerDevicesLoc}
                    defaultText={getDeviceNameById(speakerDeviceSelected, speakerDevicesLoc)}
                    handle={selectAudioLoc}
                  />
                </div>
            </div>

              {/* Temporary commented - didn't finish */}

              {/* <div>
                    <div className="mb-6">
                        <AudioInput />
                    </div>
                    <OrangeButtonOutlined text="Test" imageData={{src: "/icons/triangle.svg", alt: "triangle", width: 12, height: 16}} handle={() => console.log('test')} />
                </div> */}
            </div>
          </div>
          <div className="flex flex-row justify-end px-4 pt-4 pb-0 border-t border-[var(--Mako)]">
            <button onClick={() => leaveSession()} className="bg-[#313139] hover:bg-[#44444D] ease-in-out duration-200 cursor-pointer body-rf-12 text-white py-2 px-6 shadow-cancel-button rounded-[8px] mr-4">
              Leave Session
            </button>
            <button
              onClick={() => askToJoin()}
             
              type="submit"
              className="bg-gradient-to-t from-[#fb6520b8] to-[#fb6520db] hover:bg-[#FB6520] ease-in-out duration-200 cursor-pointer body-rf-12 text-white py-2 px-6 shadow-primary-button rounded-[8px]"
            >
              Ask To Join
            </button>
          </div>
        </div>
      }
    </>
  );
}
