import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Director, View } from '@millicast/sdk';
import { useGetCurrentUser, useWatermark } from "../../../../hooks/useServerQueries";
import { IDolbyConfig } from 'types/';
import { StreamContext } from 'context/';
import {millicast} from "../../../../utils/milicast";
import AudioLevelMeter from 'ui/components-library/AudioLevelMeter';

export default function StreamElement() {
  const { 
    streamOutputGain, setStreamOutputLevel, setStreamOutputGain,
    dolbyStreamingConfig, streamState, setStreamState, roomData, muteCallOutput, muteStreamOutput, audioElement, speakerDeviceSelected, setAudioElement } =
    useContext(StreamContext);
  const videoEl = useRef<any>(null);
  const [val, setVal] = useState<MediaStream>();
  const sessionId = localStorage.getItem('sessionId')
  const { mutateAsync: Watermark } = useWatermark(sessionId);
  const { mutateAsync: CurrentUser } = useGetCurrentUser()
  const [watermarkConfig, setWatermarkConfig] = useState<any>()
  const [currentUserData, setCurrentUserData] = useState<any>()
  const [volume, setVolume] = useState(0); // State for volume level
  const audioElementRef = useRef<HTMLAudioElement | null>(null);

  const watermark = async () => {
      if (sessionId) {
        const watermarkConfig = await Watermark(sessionId)
        if (watermarkConfig) {
          const currentUserData = await CurrentUser();
          setWatermarkConfig(watermarkConfig)
          setCurrentUserData(currentUserData)
        }
      }
    }
  // const VolumeMeter = ({ volume }: { volume: number }) => {
  //   return <div style={{ position: 'absolute', width: `${volume}%`, backgroundColor: 'green', height: '20px' }} />;
  // };
  useEffect(() => {
    watermark()
  }, []);

  useEffect(() => {
    if (dolbyStreamingConfig) {
      millicast(dolbyStreamingConfig, videoEl, setStreamState)
        .then(() => {
          // Once the video element is initialized, set it as the audioElement in context
          setAudioElement(videoEl.current);
          
          // Set initial volume and mute state from context
          videoEl.current.volume = streamOutputGain;
          videoEl.current.muted = muteStreamOutput;
        })
        .catch((err) => {
          console.error('Error initializing Millicast stream:', err);
        });
    }
  }, [dolbyStreamingConfig, videoEl]);

  useEffect(()=>{
    if(speakerDeviceSelected !==videoEl.current.sinkId){
      videoEl.current.setSinkId(speakerDeviceSelected).catch((err:any) => console.error('Error setting audio output device:', err));
      }
  }, [speakerDeviceSelected])

  useEffect(()=>{
    videoEl.current.muted = muteStreamOutput
    //videoEl.current.volume = streamOutputGain
},[muteStreamOutput])

  return (
    <div className="h-[calc(100%-(40px+36px+76px))]">
     
      <div className="relative flex items-center justify-center w-full h-full" style = {{backgroundColor: 'black'}}>
      
        <video
            ref={videoEl}
            id="videoMain"
            autoPlay
            
            style = {{visibility: `${(streamState=='Playing')?'visible':'hidden'}`, width: '100%', height: '100%'}}
        />
        
        {/*<VolumeMeter volume={volume} />  Render the volume meter */}

        {(currentUserData && watermarkConfig) &&
            <div className="absolute top-0 left-0 w-full h-full">
              <p className="text-center textt-xl pt-2">{`${currentUserData?.firstName} ${currentUserData?.lastName} ${watermarkConfig?.ipOverlay && roomData.ownExternalIp?roomData.ownExternalIp:''}`}</p>
            </div>
        }
     
      </div>


    </div>
  );
}
