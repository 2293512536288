import React from 'react';

interface IStreamState {
  streamName: string;
  streamState: string;
}

export default function StreamHeader({
  streamName,
  streamState,
}: Readonly<IStreamState>) {

  function getBadgeColor(streamState: string) {
    switch (streamState) {
      case 'Playing':
        return '#27AE60';
      case 'Paused':
        return '#F44336';
      default:
        return 'grey';
    }
  }

  return (
    <div className="flex flex-row justify-between items-center px-4 py-4 bg-[#313139] border-t-[#44444D] border-t">
      <p className="text-[#FB6520] capitalize">{streamName}</p>
      {streamState && <div className="px-2 py-1 rounded-md" style = {{ backgroundColor: streamState === 'Playing'? '#27AE60' : '#F44336'}}>
        <p>{streamState}</p>
      </div>}
    </div>
  );
}
